const Inputs = ({
    firstWord,
    secondWord,
    firstWordChangeHandler,
    secondWordChangeHandler,
    handleDownloadImage,
}) => {
    return (
        <div className="inputs">
            <input
                type="text"
                value={firstWord}
                onChange={(e) => {
                    firstWordChangeHandler(e.target.value);
                }}
            />
            <input
                type="text"
                value={secondWord}
                onChange={(e) => {
                    secondWordChangeHandler(e.target.value);
                }}
            />
            <button className="download-btn" onClick={handleDownloadImage}>
                Download
            </button>
        </div>
    );
};

export default Inputs;
