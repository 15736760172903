import { useState, useRef } from "react";
import "./App.css";
import Inputs from "./components/Inputs";
import Display from "./components/Display";

import html2canvas from "html2canvas";

function App() {
    const [firstWord, setFirstWord] = useState("Corn");
    const [secondWord, setSecondWord] = useState("hub");

    const firstWordChangeHandler = (enteredWord) => {
        setFirstWord(enteredWord);
        console.log("aga");
    };

    const secondWordChangeHandler = (enteredWord) => {
        setSecondWord(enteredWord);
    };

    const printRef = useRef();

    const handleDownloadImage = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);

        const data = canvas.toDataURL("image/jpg");
        const link = document.createElement("a");

        if (typeof link.download === "string") {
            link.href = data;
            link.download = "image.jpg";

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(data);
        }
    };

    return (
        <div className="App">
            <Inputs
                firstWord={firstWord}
                secondWord={secondWord}
                firstWordChangeHandler={firstWordChangeHandler}
                secondWordChangeHandler={secondWordChangeHandler}
                handleDownloadImage={handleDownloadImage}
            />
            <Display
                firstWord={firstWord}
                secondWord={secondWord}
                printRef={printRef}
            />
        </div>
    );
}

export default App;
