const Display = ({ firstWord, secondWord, printRef }) => {
    return (
        <div className="result" ref={printRef}>
            <span className="first">{firstWord}</span>
            <span className="second">{secondWord}</span>
        </div>
    );
};

export default Display;
